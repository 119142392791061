<template>
  <footer class="bp-footer" v-if="getContent">
    <div class="bp-footer__content">
      <div class="bp-footer__social-content">
        <p
          class="bp-footer__social-header text--uppercase text-editorial-heading-3"
        >
          {{ getContent.socialHeaderText }}
        </p>
        <p class="bp-footer__social-text text-body">
          {{ UI_ELEMENT("footer_newsletter_copy") }}
        </p>
        <NewsletterInput class="bp-footer__newsletter-form" />
        <div class="bp-footer__social-icons">
          <a
            class="bp-footer__social-icon-entry fill-white"
            :href="getContent.instagramUrl"
            target="_blank"
            ><Instagram
          /></a>
          <a
            class="bp-footer__social-icon-entry fill-white"
            :href="getContent.twitterUrl"
            target="_blank"
            ><Twitter
          /></a>
          <a
            class="bp-footer__social-icon-entry fill-white"
            :href="getContent.facebookUrl"
            target="_blank"
            ><Facebook
          /></a>
          <a
            class="bp-footer__social-icon-entry fill-white"
            :href="getContent.youtubeUrl"
            target="_blank"
            ><Youtube
          /></a>
          <a
            class="bp-footer__social-icon-entry"
            :href="getContent.tikTokUrl"
            target="_blank"
            ><TikTok
          /></a>
        </div>
      </div>
      <div class="bp-footer__menu-holder">
        <div v-for="index in 3" :key="index" class="bp-footer__menu-entry">
          <p
            class="bp-footer__menu-header text-commerce-heading-2 text--uppercase"
          >
            {{ getContent[`menu${index}Header`] }}
          </p>
          <div class="bp-footer__menu-links">
            <LinkTag
              v-for="(route, idx) in getMenuLinks[index]"
              :key="idx"
              :link="route.url"
              class="bp-footer__menu-link text-commerce-intro no-link-styles mb-16"
            >
              {{ route.title }}
            </LinkTag>
          </div>
        </div>
      </div>
      <div class="bp-footer__branch-id text--clr-grey_30 text--uppercase mt-20">
        {{ getBranchId }}
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex"
import Instagram from "@/assets/instagram.svg"
import Twitter from "@/assets/twitter.svg"
import Facebook from "@/assets/facebook.svg"
import Youtube from "@/assets/youtube.svg"
import TikTok from "@/assets/tiktok.svg"
import NewsletterInput from "@/components/NewsletterInput"

import ContentfulMixin from "../../contentfulComponents/ContentfulMixin"

export default {
  name: "FooterModule", // footerModule to avoid confusion with HTML5 tag

  contentfulModel: "Footer",

  mixins: [ContentfulMixin],

  components: {
    Instagram,
    Twitter,
    Facebook,
    Youtube,
    TikTok,
    NewsletterInput
  },

  data: () => ({
    menuLinks: {}
  }),

  computed: {
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),

    getBranchId() {
      return window.bleach_ab_branch
        ? "Build v." +
            window.bleach_ab_branch
              .replace("/", "-")
              .split("-")
              .map(part =>
                /\b[^\d\W]+\b/.test(part) ? part.substring(0, 1) : part
              )
              .join("")
        : ""
    },

    getMenuLinks() {
      let _linkSets = {}
      for (let i = 1; i <= 3; i++) {
        _linkSets[i] = this.getContent[`menu${i}LinksCollection`].items.map(
          item => {
            // Init any 'external' link declaration
            let url = item.externalLink

            if (item.internalLink) {
              // Prioritise any 'internal' link declaration
              return {
                title: item.title,
                url: "/" + this.fullSlugFromEntry(item.internalLink)
              }
            } else {
              return { title: item.title, url }
            }
          }
        )
      }
      return _linkSets
    }
  }
}
</script>
<style lang="scss">
.bp-footer {
  position: relative;
  z-index: 9;
  background-color: getcolour(bleach_black);
  color: getcolour(bleach_white);
  overflow: hidden;
  clear: both;

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      align-items: center;
      width: calc(100% - 80px);
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      flex-direction: row;
      padding: 40px 0 60px;
      margin: 0 auto;
      max-width: $bleach-site-max-width;
    }
  }

  &__social-content {
    padding: 26px 25px 0;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      padding: 0 36px 0 0;
      width: auto;
      max-width: 400px;
    }
  }

  &__social-header {
    margin-bottom: 6px;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      text-align: initial;
    }
  }

  &__social-text {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      text-align: center;
      margin-bottom: 12px;
    }

    @include media-breakpoint-up(lg) {
      text-align: initial;
      margin-bottom: 35px;
    }
  }

  &__social-cta {
    display: flex;
    height: 34px;
    margin-bottom: 22px;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      height: auto;
    }
  }

  &__social-input {
    padding: 10px 5px 10px 13px;
    border-radius: 0;
    border: 1px solid $bleach-white;
  }

  &__social-button {
    padding: 11px 22px;
    border-radius: 0;
    border: 0;
    background: $bleach-white;
    color: $bleach-black;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      width: 192px;
      margin-top: 20px;
    }
  }

  &__newsletter-form {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 330px;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  &__social-icons {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  &__social-icon-entry {
    width: 32px;
    margin-right: 20px;
    height: 32px;
    cursor: pointer;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 15px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .fill-white path {
    fill: $bleach-white;
  }

  &__menu-header {
    margin-bottom: 8px;
  }

  &__menu-holder {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    flex-grow: 1;
    border-top: 1px solid getcolour(bleach_grey_20);
    padding-top: 32px;

    @include media-breakpoint-up(md) {
      width: 100%;
      margin: 30px auto;
      flex-direction: row;
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      border-top: 0;
      padding-top: 0;
      margin-left: 110px;
      flex: 1;
      margin-top: 0;
    }
  }

  &__menu-entry {
    padding: 0 25px 40px;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      border: 0;
      padding: 30px 0 0;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }

  &__menu-links {
    display: flex;
    flex-direction: column;
  }

  &__branch-id {
    font-size: 1rem;
    flex: 1 0 100%;
    padding: 0 30px 80px;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }
}
</style>
